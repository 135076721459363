import {css} from '@emotion/core';
import {Card, Col, Descriptions, Row} from 'antd';
import {FC, memo} from 'react';
import {Link} from 'react-router-dom';
import {useCurrentLine, usePatient} from '../../contexts/Quote';
import {colors, cssRowWithoutOuterGutter} from '../../util/render';
import {getSelectedEars, SELECTED_EARS} from '../Options/static';
import OptionDescription from '../Summary/OptionDescription';
import OrderDescription from '../Summary/OrderDescription';
import PatientDescription from '../Summary/PatientDescription';

const containerCss = css`
  .ant-col > .ant-card {
    height: 100%;
  }

  .ant-descriptions-item-label {
    color: ${colors.secondary};
  }
`;

const imageDescriptionCss = css`
  margin: 5px 0;
  .ant-descriptions-item-content {
    height: 21px;
    img {
      max-height: 100%;
      margin-right: 5px;
    }
  }
`;

const responsiveHeaderCss = css`
  .ant-col {
    padding: 2px 0;
    @media (max-width: 1600px) {
      flex: 1;
      margin: 0;
    }
  }
`;

const patientCardCss = css`
  position: relative;
  background-color: #ececf1;
  border: none;
  text-align: left;

  .ant-descriptions {
    padding: 2px 0px;
  }
  .ant-col {
    padding: 0px;
  }

  .ant-descriptions-title {
    color: #78787d;
    margin-bottom: 0;
  }

  .icon-container {
    position: absolute;
    top: -25px;
    left: -25px;
    background-color: #212529;
    width: 50px;
    border-radius: 25px;
    padding: 5px;

    img {
      max-width: 100%;
    }
  }

  .ant-descriptions-row > td {
    padding-bottom: 0;
  }

  .ant-descriptions-item-label {
    color: #78787d !important;
  }

  .ant-descriptions-item-content {
    color: #78787d;
    display: inline-block;
  }

  .ant-card-body {
    padding: 7px 20px;
    padding-bottom: 15px;
  }

  &.slim .ant-card-body {
    max-height: 21px;
  }
`;

interface HeaderSummaryProps {
  patientOnly?: boolean;
  parentPath: string;
}

const HeaderSummary: FC<HeaderSummaryProps> = ({
  patientOnly = false,
  parentPath,
}) => {
  const [{category, subCategory, product, brand, options}] = useCurrentLine();
  const [patient] = usePatient();

  if (!patient?.name) return null;

  return (
    <Row
      type="flex"
      css={[containerCss, cssRowWithoutOuterGutter, responsiveHeaderCss]}
      gutter={{xl: 0, sm: 5}}
    >
      {!patientOnly ? (
        <Col>
          <Card>
            <OrderDescription
              descriptionSpan={3}
              title={
                <Link to={`${parentPath}/patient`}>Informations commande</Link>
              }
            />
          </Card>
        </Col>
      ) : null}
      <Col span={24}>
        <Card css={patientCardCss} className={!patient?.name ? 'slim' : ''}>
          {/* <div className="icon-container">
            <img src="/2022/nimes.svg" alt="Logo site" />
          </div> */}
          <Row>
            <Col lg={24} span={6}>
              <PatientDescription
                descriptionSpan={3}
                title="Références Patient"
              />
            </Col>

            <Col lg={24} span={6}>
              {category ? (
                <Descriptions
                  title="Catégorie"
                  layout="horizontal"
                  size="small"
                  css={imageDescriptionCss}
                >
                  <Descriptions.Item label="" span={3}>
                    {category.label}
                  </Descriptions.Item>
                </Descriptions>
              ) : null}
            </Col>

            <Col lg={24} span={6}>
              {subCategory ? (
                <Descriptions
                  title="Domaine"
                  layout="horizontal"
                  size="small"
                  css={imageDescriptionCss}
                >
                  <Descriptions.Item label="" span={3}>
                    {subCategory}
                  </Descriptions.Item>
                </Descriptions>
              ) : null}
            </Col>

            <Col lg={24} span={6}>
              {product ? (
                <Descriptions
                  title="Produit et marque"
                  layout="horizontal"
                  size="small"
                  css={imageDescriptionCss}
                >
                  <Descriptions.Item label="" span={3}>
                    {product.label}
                  </Descriptions.Item>
                  {brand ? (
                    <Descriptions.Item label="Marque" span={3}>
                      {brand.label}
                    </Descriptions.Item>
                  ) : null}
                </Descriptions>
              ) : null}
            </Col>

            <Col span={24}>
              {getSelectedEars(options.ears) !== SELECTED_EARS.NONE ? (
                <OptionDescription
                  descriptionSpan={1}
                  options={options}
                  showSelectedOptions={false}
                  title="Oreilles"
                />
              ) : null}
            </Col>

            <Col span={24} style={{margin: '5px 0'}}>
              {getSelectedEars(options.ears) !== SELECTED_EARS.NONE ? (
                <OptionDescription
                  descriptionSpan={3}
                  options={options}
                  showSelectedEars={false}
                  title="Options"
                />
              ) : null}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default memo(HeaderSummary);
