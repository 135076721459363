import {Button, Descriptions, Icon, Modal, Popconfirm, Table} from 'antd';
import {SortOrder} from 'antd/es/table';
import {PaginationConfig} from 'antd/lib/pagination';
import {ColumnProps} from 'antd/lib/table';
import {SorterResult, TableCurrentDataSource} from 'antd/lib/table/interface';
import moment from 'moment';
import {FC, Fragment, memo, ReactNode, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  BaseQuoteData,
  DEAFNESS_LABEL,
  GENDER,
  QuoteLine,
  transferFabricationMethod,
  TransferMethod,
  UndefinedTuple,
  useQuote,
} from '../../contexts/Quote';
import {useSession, useUser} from '../../contexts/Session';
import {User, UserType} from '../../util/fetch-wakanda';
import {
  Currency,
  fixTimestampMilliseconds,
  renderTimestamp,
  StateOf,
} from '../../util/render';
import {getFilterDropdownProps, Search} from '../../util/SearchFilter';
import {
  BOOLEAN_VALUE,
  colorLabels,
  deporteOptionLabels,
  deporteOptionsRules,
  deporteOptionsTypeEmbout,
  deporteShapeRule,
  DEPORTE_OPTION,
  DEPORTE_SHAPE,
  earBudLabels,
  earWaxShieldLabels,
  earWaxShieldRules,
  EVENT,
  eventLabels,
  eventRules,
  FILTER,
  FINISH,
  finishLabels,
  materialLabels,
  MISCELLANEOUS,
  miscellaneousRules,
  obturateurProductTypeLabels,
  OPTION,
  optionEmboutLabelRules,
  optionLabels,
  optionRules,
  optionTubeLabels,
  optionTubeRules,
  OPTION_TUBE,
  PRODUCT_CATEGORY,
  shapeLabels,
  shoulderLabels,
  SURFACE,
  surfaceLabels,
  TUBE,
  tubeLabels,
  tubeRules,
  TYPE,
} from '../Options/data';
import {getOptionLabel} from '../Options/OptionsGroups';
import {productCategories} from '../Quote/data';
import {asapShippingDate} from '../Quote/patient/Form';
import CertificateModal from '../Summary/CertificateModal';
import DocumentModal from '../Summary/DocumentModal';
import StickerModal from '../Summary/StickerModal';
import TechnicalModal from '../Summary/TechnicalModal';
import {Article, getBaseRule, getLabelFromRule, getRule} from '../Summary/util';
import {
  BetterOrder,
  Client,
  ORDER_STATE,
  ORDER_STATE_INFO,
  OREILLE,
  PRODUCTION_STATE_INFO,
  Transporteur,
} from './data';

const oreilleProps: {[key in keyof OREILLE]?: string} = {
  categorie: 'Catégorie',
  produit: 'Produit',
  deporteBrand: 'Marque',
  ecouteur: 'Ecouteur',
  bague: 'Bague',
  matiere: 'Matière',
  forme: 'Forme',
  couleur: 'Couleur',
  finition: 'Finition',
  event: 'Event',
  eventDiam: 'Diamètre event',
  eventSurMesure: 'Event sur mesure',
  tube: 'Tube',
  optionEmbout: 'Options embout',
  optionAquason: 'Option Aquason',
  optionEP2: 'Option EP2',
  optionCordonEP2: 'Cordon',
  optionGravureEP2: 'Gravure',
  optionObturateur: 'Options Obturateur',
  optionPianissimo: 'Options Pianissimo',
  optionPasstopT: 'Options Passtop T',
  optionTir: 'Option Tir',
  optionsEcouteur: 'Options écouteur',
  vernisAntibacterien: 'Vernis antibactérien',
  repereCouleur: 'Repère couleur',
  otoscan: 'Otoscan',
  sousGarantie: 'Sous garantie',
  typeEmbout: 'Type embout',
  optionDeporte: 'Option déporté',
  customMark: 'Autre marquage',
  tubeCouleur: 'Tube coloré',
  typeObturateur: 'Type obturateur',
  optionIros: 'Option Iros',
  formeDeporte: 'Forme',
};

const getOreilleDescription = (label: string, oreille: OREILLE): ReactNode => {
  const items: ReactNode[] = [];

  Object.entries(oreilleProps).map((entry): void => {
    const key = entry[0] as keyof OREILLE;
    let value: string | string[] | boolean | ReactNode = oreille[key];

    console.log(key, value);

    if (value && (!Array.isArray(value) || value.length > 0)) {
      switch (key) {
        case 'forme':
          if (oreille.formeEpaulement) {
            value += ' (' + oreille.formeEpaulement + ')';
          }
          break;
        case 'eventDiam':
        case 'eventSurMesure':
          value += ' mm';
          break;
        case 'event':
          if (typeof value === 'string') {
            value = getLabelFromRule(value, eventRules, eventLabels, true);
          }
          break;
        case 'tube':
          if (typeof value === 'string') {
            value = getLabelFromRule(value, tubeRules, tubeLabels, true);
          }
          break;
        case 'optionEmbout':
          if (
            typeof value === 'object' &&
            Array.isArray(value) &&
            value.length > 0
          ) {
            value = (value as string[])
              .map((val) => {
                const labelRule = Object.entries(optionEmboutLabelRules).find(
                  ([, _val]) => _val === val,
                );

                if (labelRule) {
                  return optionLabels[labelRule[0] as OPTION];
                }

                return getLabelFromRule(
                  'OPT_' + val,
                  optionTubeRules,
                  optionTubeLabels,
                );
              })
              .join(', ');
          }
          break;
        case 'optionAquason':
          if (typeof value === 'string') {
            value = getLabelFromRule(value, optionRules, optionLabels);
          }
          break;
        case 'optionEP2':
          if (typeof value === 'string') {
            value = getLabelFromRule(value, optionRules, optionLabels);
          }
          break;
        /* case 'optionCordonEP2':
          if (typeof value === 'string') {
            value = getLabelFromRule(value, cordColorRules, cordColorLabels);
          }
          break; */
        case 'optionGravureEP2':
          if (typeof value === 'string' && getRule(value) === 'OPT_option06') {
            value = 'Prénom + nom';
          }
          break;
        case 'optionObturateur':
        case 'optionPasstopT':
          if (
            typeof value === 'object' &&
            Array.isArray(value) &&
            value.length > 0
          ) {
            value = (value as string[])
              .map((val) => getLabelFromRule(val, optionRules, optionLabels))
              .join(', ');
          }
          break;
        case 'optionPianissimo':
          if (
            typeof value === 'object' &&
            Array.isArray(value) &&
            value.length > 0
          ) {
            value = (value as string[]).join(', ');
          }
          break;
        case 'optionTir':
          if (typeof value === 'string' && getRule(value) === 'OPT_option01') {
            value = 'Camouflage';
          }
          break;
        // TODO shield
        /* case 'optionsEcouteur':
          if (
            typeof value === 'object' &&
            Array.isArray(value) &&
            value.length > 0
          ) {
            value = (value as string[])
              .map((val) =>
                getLabelFromRule(
                  val,
                  {
                    ...miscellaneousRules,
                    ...earwaxShieldingRules,
                    ...{montage: 'OPT_montage'},
                  },
                  {
                    ...miscellaneousLabels,
                    ...earwaxShieldingLabels,
                    ...{montage: 'Montage écouteur'},
                  },
                ),
              )
              .join(', ');
          }
          break; */
        case 'vernisAntibacterien':
          if (typeof value === 'string' && getRule(value) === 'OPT_foption01') {
            value = true;
          }
          break;
        case 'formeConque':
          value += ' TODO (formeConque)';
          break;
        case 'typeEmbout':
          const typeEmboutLabel = Object.entries(deporteOptionsTypeEmbout).find(
            ([, label]) => label === value,
          );

          if (typeEmboutLabel) {
            value = deporteOptionLabels[typeEmboutLabel[0] as DEPORTE_OPTION];
          }
          break;
        case 'optionDeporte':
          if (
            typeof value === 'object' &&
            Array.isArray(value) &&
            value.length > 0
          ) {
            value =
              (value as string[])
                .map((val) =>
                  getLabelFromRule(
                    val,
                    deporteOptionsRules,
                    deporteOptionLabels,
                  ),
                )
                .filter((value) => !!value)
                .join(', ') || 'N/A';
          }
          break;
        case 'optionsEcouteur':
          if (
            typeof value === 'object' &&
            Array.isArray(value) &&
            value.length > 0
          ) {
            value = (value as string[])
              .map((val) =>
                getLabelFromRule(val, earWaxShieldRules, earWaxShieldLabels),
              )
              .join(', ');
          }
          break;
      }

      if (typeof value === 'boolean') {
        value = <Icon type="check" style={{color: 'green'}} />;
      }

      items.push(
        <Descriptions.Item key={items.length} label={entry[1]}>
          {value}
        </Descriptions.Item>,
      );
    }

    return undefined;
  });

  return (
    <Descriptions
      title={label}
      bordered
      size="small"
      column={1}
      style={{marginBottom: 16}}
    >
      {items}
    </Descriptions>
  );
};

const getQuoteFrom4D = (quote: BetterOrder): BaseQuoteData | undefined => {
  const {ID: id, details} = quote;
  const oreilles = [details.OG, details.OD];

  let tmpOreille = oreilles[0];
  if (!tmpOreille) {
    tmpOreille = oreilles[1];
  }
  if (!tmpOreille) {
    return undefined;
  }
  const oreille: OREILLE = tmpOreille;

  const category = productCategories.find(
    ({label}) => label === oreille.categorie,
  );
  if (!category) {
    return undefined;
  }

  const products = Array.isArray(category.products)
    ? category.products
    : Object.values(category.products).flat();

  const product = products.find(
    ({label}) => label === oreille.produit || label === oreille.marque,
  );
  if (!product) {
    return undefined;
  }

  const brand = (category.brands || []).find(
    ({label}) => label === oreille.deporteBrand,
  );

  const quoteLine: QuoteLine = {
    id,
    category,
    product,
    brand,
    options: {
      ears: [!!oreilles[0], !!oreilles[1]],
      lockedEars: false,
      selected: [],
    },
    valid: true,
  };

  const baseRule = getBaseRule(quoteLine);

  const setSelected = (
    index: number,
    type: TYPE,
    name: string,
    value?: number | string,
  ): void => {
    const selected = quoteLine.options.selected.find(
      ({type: optType, name: optName}) => optType === type && optName === name,
    );
    let val: UndefinedTuple<number | string> | undefined;
    if (value) {
      val = [
        (index === 0 && value) || undefined,
        (index === 1 && value) || undefined,
      ] as UndefinedTuple<number | string>;
    }
    if (selected) {
      selected.selected[index] = true;
      if (selected.value) {
        selected.value[index] = value;
      } else {
        selected.value = val;
      }
    } else {
      quoteLine.options.selected.push({
        type,
        name,
        label: getOptionLabel(type, name),
        selected: [index === 0, index === 1],
        value: val,
      });
    }
  };

  const getEntry = (
    labels: {[key: string]: string},
    label: string | null,
    rule = false,
  ): [string, string] | undefined =>
    (label &&
      Object.entries(labels).find(
        ([, value]) => (rule ? baseRule + value + ':/' : value) === label,
      )) ||
    undefined;

  [...Object.keys(oreilleProps), 'formeEpaulement', 'article_regle'].map(
    (key): void => {
      oreilles.map((oreille, index): void => {
        if (!oreille) {
          return undefined;
        }

        const value: string | string[] | boolean | null | undefined =
          oreille[key as keyof OREILLE];

        if (value && (!Array.isArray(value) || value.length > 0)) {
          switch (key) {
            case 'article_regle':
              if (oreille.article_regle) {
                const rule = getRule(oreille.article_regle);
                const shapeRuleMatch = rule.match(/[a-zA-Z]+_[a-zA-Z]+$/);
                if (shapeRuleMatch) {
                  const entry = getEntry(
                    deporteShapeRule as {
                      [material in DEPORTE_SHAPE]: string;
                    },
                    shapeRuleMatch[0],
                  );

                  if (entry) {
                    setSelected(index, TYPE.SHAPE, entry[0]);
                  }
                }

                switch (rule) {
                  case 'EBT_filtre15':
                    setSelected(index, TYPE.FILTER, FILTER.DB_15);
                    break;
                  case 'EBT_filtre25':
                    setSelected(index, TYPE.FILTER, FILTER.DB_25);
                    break;
                  case 'EBT_filtre30':
                    setSelected(index, TYPE.FILTER, FILTER.DB_30);
                    break;
                  case 'EBT_T3':
                    setSelected(index, TYPE.FILTER, FILTER.SNR22);
                    break;
                  case 'EBT_T4':
                    setSelected(index, TYPE.FILTER, FILTER.SNR24);
                    break;
                  case 'EBT_C2':
                    setSelected(index, TYPE.FILTER, FILTER.SNR21);
                    break;
                  case 'EBT_C3':
                    setSelected(index, TYPE.FILTER, FILTER.SNR26);
                    break;
                }
              }
              break;
            case 'matiere': {
              const entry = Object.entries(materialLabels).find((entry) => {
                if (entry[1] !== oreille.matiere) {
                  return false;
                }

                const [cat, prod] = entry[0].split('_');

                if (quoteLine.category.name !== cat) {
                  return false;
                }

                /* if (
                  quoteLine.category.name !== PRODUCT_CATEGORY.DEPORTE &&
                  quoteLine.product.name !== [cat, prod].join('_')
                ) {
                  return false;
                } */

                return true;
              });

              if (entry) {
                setSelected(index, TYPE.MATERIAL, entry[0]);
              }

              break;
            }
            case 'forme': {
              const entry = getEntry(
                shapeLabels,
                [oreille.forme || 'Épaulement', oreille.formeEpaulement || '']
                  .join(' ')
                  .trim(),
              );

              if (entry) {
                setSelected(index, TYPE.DEPORTE_BRAND, entry[0]);
              }

              break;
            }
            case 'couleur': {
              const entry = getEntry(colorLabels, oreille.couleur);

              if (entry) {
                setSelected(index, TYPE.COLOR, entry[0]);
              }

              break;
            }
            case 'formeEpaulement': {
              const entry = getEntry(
                shoulderLabels,
                ['Épaulement', oreille.formeEpaulement].join(' ').trim(),
              );

              if (entry) {
                setSelected(index, TYPE.SHOULDER, entry[0]);
              }

              break;
            }
            case 'ecouteur': {
              const entry = getEntry(earBudLabels, oreille.ecouteur);

              if (entry) {
                setSelected(index, TYPE.EAR_BUD, entry[0]);
              }

              break;
            }
            case 'eventSurMesure':
              setSelected(
                index,
                TYPE.EVENT,
                EVENT.CUSTOM,
                Number(oreille.eventSurMesure) || 0,
              );
              break;
            case 'eventDiam':
              {
                const entry = getEntry(
                  eventLabels,
                  [oreille.eventDiam, 'mm'].join(' '),
                );

                if (entry) {
                  setSelected(index, TYPE.EVENT, entry[0]);
                }
              }
              break;
            case 'event':
              {
                const entry = getEntry(
                  eventRules as {[key in EVENT]: string},
                  oreille.event,
                  true,
                );

                if (entry) {
                  setSelected(index, TYPE.EVENT, entry[0]);
                }
              }
              break;
            case 'tube':
              {
                const entry =
                  getEntry(
                    tubeRules as {[key in TUBE]: string},
                    oreille.tube,
                  ) ||
                  getEntry(
                    tubeRules as {[key in TUBE]: string},
                    oreille.tube,
                    true,
                  );

                if (entry) {
                  setSelected(index, TYPE.TUBE, entry[0]);
                }
              }
              break;
            case 'optionEmbout':
              if (
                typeof value === 'object' &&
                Array.isArray(value) &&
                value.length > 0
              ) {
                (value as string[]).map((val): void => {
                  {
                    const labelEntry = getEntry(
                      optionEmboutLabelRules as {[key in OPTION]: string},
                      val,
                    );

                    if (labelEntry) {
                      setSelected(index, TYPE.OPTION, labelEntry[0]);
                    }
                  }
                  {
                    const ruleEntry = getEntry(
                      optionTubeRules as {[key in OPTION_TUBE]: string},
                      val,
                      true,
                    );

                    if (ruleEntry) {
                      setSelected(index, TYPE.OPTION_TUBE, ruleEntry[0]);
                    }
                  }

                  return undefined;
                });
              }
              break;
            case 'finition': {
              const surfaceEntry = getEntry(
                surfaceLabels as {[key in SURFACE]: string},
                oreille.finition,
              );

              if (surfaceEntry) {
                setSelected(index, TYPE.SURFACE, surfaceEntry[0]);
                break;
              }

              const finishEntry = getEntry(
                finishLabels as {[key in FINISH]: string},
                oreille.finition,
              );

              if (finishEntry) {
                setSelected(index, TYPE.FINISH, finishEntry[0]);
                break;
              }

              break;
            }
            case 'repereCouleur': {
              if (oreille.repereCouleur === true) {
                if (quoteLine.category.name === PRODUCT_CATEGORY.DEPORTE) {
                  setSelected(
                    index,
                    TYPE.MISCELLANEOUS,
                    MISCELLANEOUS.COLOR_MARKER,
                  );
                } else {
                  setSelected(index, TYPE.COLOR_MARKER, BOOLEAN_VALUE);
                }
              }

              break;
            }
            case 'optionAquason':
              if (
                typeof value === 'string' &&
                getRule(value) === 'OPT_option01'
              ) {
                setSelected(index, TYPE.FLOAT, BOOLEAN_VALUE);
              }
              break;
            case 'optionEP2':
              {
                const entry = getEntry(
                  optionRules as {[key in OPTION]: string},
                  oreille.optionEP2,
                  true,
                );

                if (entry) {
                  setSelected(index, TYPE.OPTION, entry[0]);
                }
              }
              break;
            /* case 'optionCordonEP2':
              {
                const entry = getEntry(
                  cordColorRules as {[key in CORD_COLOR]: string},
                  oreille.optionCordonEP2,
                  true,
                );

                if (entry) {
                  setSelected(index, TYPE.CORD_COLOR, entry[0]);
                }
              }
              break; */
            case 'optionGravureEP2':
              if (
                typeof value === 'string' &&
                getRule(value) === 'OPT_option06'
              ) {
                setSelected(index, TYPE.ENGRAVING, BOOLEAN_VALUE);
              }
              break;
            case 'optionObturateur':
            case 'optionPasstopT':
              if (
                typeof value === 'object' &&
                Array.isArray(value) &&
                value.length > 0
              ) {
                (value as string[]).map((val): void => {
                  const entry = getEntry(
                    optionRules as {[key in OPTION]: string},
                    val,
                    true,
                  );

                  if (entry) {
                    setSelected(index, TYPE.OPTION, entry[0]);
                  }

                  return undefined;
                });
              }
              break;
            case 'optionPianissimo':
              if (
                typeof value === 'object' &&
                Array.isArray(value) &&
                value.length > 0
              ) {
                (value as string[]).map((val): void => {
                  const entry = getEntry(optionLabels, val);

                  if (entry) {
                    setSelected(index, TYPE.OPTION, entry[0]);
                  }

                  return undefined;
                });
              }
              break;
            case 'optionTir':
              if (
                typeof value === 'string' &&
                getRule(value) === 'OPT_option01'
              ) {
                setSelected(index, TYPE.CAMO, BOOLEAN_VALUE);
              }
              break;
            case 'optionsEcouteur':
              if (
                typeof value === 'object' &&
                Array.isArray(value) &&
                value.length > 0
              ) {
                (value as string[]).map((val): void => {
                  {
                    const entry = getEntry(
                      miscellaneousRules as {[key in MISCELLANEOUS]: string},
                      val,
                      true,
                    );
                    if (entry) {
                      setSelected(index, TYPE.MISCELLANEOUS, entry[0]);
                    }
                  }
                  {
                    const entry = getEntry(earWaxShieldRules, val);
                    if (entry) {
                      setSelected(index, TYPE.EARWAX_SHIELDING, entry[0]);
                    }
                  }
                  {
                    const entry = getEntry({montage: 'OPT_montage'}, val, true);
                    if (entry) {
                      setSelected(index, TYPE.EAR_BUD_ASSEMBLY, BOOLEAN_VALUE);
                    }
                  }

                  return undefined;
                });
              }
              break;
            case 'vernisAntibacterien':
              if (
                typeof value === 'string' &&
                getRule(value) === 'OPT_foption01'
              ) {
                setSelected(index, TYPE.SURFACE, SURFACE.ANTIBACTERIAL);
              }
              break;
            case 'typeObturateur': {
              const entry = getEntry(
                obturateurProductTypeLabels,
                oreille.typeObturateur,
              );

              if (entry) {
                setSelected(index, TYPE.OBTURATEUR_PRODUCT_TYPE, entry[0]);
              }

              break;
            }
            case 'optionIros': {
              if (oreille.optionIros === 'iros') {
                setSelected(index, TYPE.OPTION, OPTION.IROS);
              } else if (oreille.optionIros === 'demiIros') {
                setSelected(index, TYPE.OPTION, OPTION.HALF_IROS);
              }
              break;
            }
            case 'formeDeporte': {
              const entry = getEntry(shapeLabels, oreille.formeDeporte);

              if (entry) {
                setSelected(index, TYPE.SHAPE, entry[0]);
              }
              break;
            }
            case 'optionDeporte': {
              if (
                typeof value === 'object' &&
                Array.isArray(value) &&
                value.length > 0
              ) {
                (value as string[]).map((val): void => {
                  {
                    const ruleEntry = getEntry(
                      deporteOptionsRules as {[key in DEPORTE_OPTION]: string},
                      val,
                    );
                    if (ruleEntry) {
                      if (
                        ruleEntry[0] === DEPORTE_OPTION.LEFT_RIGHT_MARK &&
                        oreille.customMark
                      ) {
                        setSelected(
                          index,
                          TYPE.DEPORTE_OPTION,
                          DEPORTE_OPTION.CUSTOM_MARK,
                          oreille.customMark,
                        );
                      } else {
                        setSelected(index, TYPE.DEPORTE_OPTION, ruleEntry[0]);
                      }
                    }
                  }

                  return undefined;
                });
              }
              break;
            }
            case 'tubeCouleur': {
              if (oreille.tubeCouleur) {
                setSelected(
                  index,
                  TYPE.TUBE,
                  TUBE.COLORED,
                  oreille.tubeCouleur,
                );
              }
              break;
            }
            case 'typeEmbout': {
              const entry = getEntry(
                deporteOptionsTypeEmbout as {[key in DEPORTE_OPTION]: string},
                oreille.typeEmbout,
              );

              if (entry) {
                setSelected(index, TYPE.DEPORTE_OPTION, entry[0]);
              }
              break;
            }
            default:
              break;
          }
        }

        return undefined;
      });

      return undefined;
    },
  );

  const {age, surdite: deafnessLabel, serial, sexe} = details.PATIENT;

  console.log(details);
  const name =
    details.PATIENT.nom +
    (details.PATIENT.prenom ? ` ${details.PATIENT.prenom}` : '');

  /* eslint-disable @typescript-eslint/camelcase */
  const {
    noBoite,
    livr_codePostal,
    livr_raisonSociale,
    id_client,
    id_transporteur,
    modeEmpreinte,
  } = quote;

  // TODO deafness, infos tech/admin, transferMethod, prints?
  return {
    patient: {
      name: name,
      age,
      gender:
        sexe === 'M' ? GENDER.MALE : sexe === 'F' ? GENDER.FEMALE : undefined,
      deafness:
        (deafnessLabel &&
          Number(
            Object.entries(DEAFNESS_LABEL).find(
              ([, label]) => label === deafnessLabel,
            )?.[0],
          )) ||
        undefined,
    },
    lines: [quoteLine],
    currentLine: quoteLine,
    terms: true,
    lineCount: 1,
    serialNumber: serial,
    boxNumber: noBoite,
    clientZipCode: livr_codePostal,
    clientId: id_client.__KEY,
    client: `${livr_raisonSociale} (client #${id_client.__KEY})`,
    transporter: {ID: id_transporteur.__KEY} as Transporteur,
    fabricationMethod:
      transferFabricationMethod[(modeEmpreinte - 1) as TransferMethod],
    warranty: !!oreilles[0]?.sousGarantie,
    waiting: quote.ATT_motif || undefined,
    comment: quote.messageInterne,
    shippingDate:
      quote.dateSouhaitee === null
        ? asapShippingDate
        : (quote.dateSouhaitee &&
            new Date(quote.dateSouhaitee).toISOString()) ||
          undefined,
    shippingAddress: {
      ID: quote.livr_id,
      type: 'L',
      raisonSociale: quote.livr_raisonSociale,
      adresse: quote.livr_adresse,
      codePostal: quote.livr_codePostal,
      ville: quote.livr_ville,
      isoCountry: quote.livr_iso_pays,
    },
    transfer: {
      method: (modeEmpreinte - 1) as TransferMethod,
      prints: details.files.map((name, index) => ({
        name,
        size: 0,
        type: '',
        uid: (-(index + 1)).toString(),
      })),
    },
  };
  /* eslint-enable @typescript-eslint/camelcase */
};

const OrderState: FC<{
  state: number;
  info: {[key: number]: {label: string; color: string}};
}> = ({state, info}) => (
  <div css={{color: info[state].color}}>{info[state].label}</div>
);

type GenericTypedProperties<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never;
}[keyof T];

const strLocaleCompare: <T>(
  key: GenericTypedProperties<T, string>,
) => (a: T, b: T) => number = (key) => (str1, str2) => {
  const [aVal, bVal] = ([str1[key], str2[key]] as unknown) as [string, string];

  return aVal.localeCompare(bVal);
};

const numberCompare: <T>(
  key: GenericTypedProperties<T, number>,
) => (a: T, b: T) => number = (key) => (num1, num2) => {
  const [aVal, bVal] = ([num1[key], num2[key]] as unknown) as [number, number];

  return aVal - bVal;
};

const timestampCompare: <T>(
  key: GenericTypedProperties<T, number>,
) => (a: T, b: T) => number = (key) => (num1, num2) => {
  const [aVal, bVal] = ([num1[key], num2[key]] as unknown) as [number, number];

  return fixTimestampMilliseconds(aVal) - fixTimestampMilliseconds(bVal);
};

interface ColumnsProps {
  user: User | null;
  searchState: StateOf<Search>;
  deleteOrder: (id: string) => Promise<boolean>;
  showDetailsModal: (id: string) => void;
  editOrder: (id: string) => void;
  editOrderWarning: boolean;
  sorter?: SorterType;
  filters?: FiltersType;
}

const columns: (props: ColumnsProps) => ColumnProps<BetterOrder>[] = ({
  user,
  searchState,
  deleteOrder,
  showDetailsModal,
  editOrder,
  editOrderWarning,
  sorter,
  filters,
}) => [
  {
    title: '',
    key: 'actions',
    render: function Actions(_, order) {
      return (
        <Fragment>
          {order.numeroBon ? (
            user?.type === UserType.CLIENT ? (
              <DocumentModal order={order} />
            ) : (
              <TechnicalModal order={order} />
            )
          ) : null}
          <Button
            shape="circle"
            icon="eye"
            onClick={() => {
              showDetailsModal(order.ID);
            }}
          />
          <div style={{marginTop: 4}}>
            {user?.type !== UserType.CLIENT ? (
              <StickerModal order={order} />
            ) : order.etat === ORDER_STATE.EXPEDIEE ? (
              <CertificateModal order={order} />
            ) : null}
            {order.etat === ORDER_STATE.EN_ATTENTE ? (
              editOrderWarning ? (
                <Popconfirm
                  placement="left"
                  title="Êtes-vous sûr de vouloir modifier cette commande ? Votre commande actuelle sera perdue"
                  onConfirm={() => {
                    editOrder(order.ID);
                  }}
                  okText="Oui"
                  cancelText="Annuler"
                >
                  <Button shape="circle" icon="edit" />
                </Popconfirm>
              ) : (
                <Button
                  shape="circle"
                  icon="edit"
                  onClick={(): void => {
                    editOrder(order.ID);

                    return undefined;
                  }}
                />
              )
            ) : null}
          </div>
        </Fragment>
      );
    },
  },
  {
    title: 'Commande',
    dataIndex: 'numeroBon',
    sorter: strLocaleCompare('numeroBon'),
    defaultSortOrder: sorter?.[0] === 'numeroBon' ? sorter[1] : undefined,
    filteredValue: filters?.numeroBon || undefined,
    ...getFilterDropdownProps('numeroBon', searchState),
  },
  // TODO code client, raison sociale
  // id_client
  /*{
    title: 'Client',
    dataIndex: 'client',
    sorter: strLocaleCompare('client'),
    ...getFilterDropdownProps('client', searchState),
  },*/
  {
    title: 'Patient',
    dataIndex: 'patient',
    sorter: strLocaleCompare('patient'),
    defaultSortOrder: sorter?.[0] === 'patient' ? sorter[1] : undefined,
    ...getFilterDropdownProps('patient', searchState),
    filteredValue: filters?.patient || undefined,
  },
  {
    title: 'Date de commande',
    dataIndex: 'dateSaisie',
    render: renderTimestamp,
    sorter: timestampCompare('dateSaisie'),
    defaultSortOrder: sorter?.[0] === 'dateSaisie' ? sorter[1] : undefined,
  },
  {
    title: 'Montant',
    dataIndex: 'montant',
    render: function renderCurrency(price) {
      return <Currency price={price} />;
    },
    // sorter: numberCompare('montant'),
  },
  {
    title: 'État',
    dataIndex: 'etat',
    render: function renderState(_, {etat, etatFabrication}) {
      return (
        <OrderState
          state={etat === ORDER_STATE.EN_COURS ? etatFabrication : etat}
          info={
            etat === ORDER_STATE.EN_COURS
              ? PRODUCTION_STATE_INFO
              : ORDER_STATE_INFO
          }
        />
      );
    },
    sorter: numberCompare('etat'),
    defaultSortOrder: sorter?.[0] === 'etat' ? sorter[1] : undefined,
  },
  {
    title: 'Date de départ',
    dataIndex: 'departSociete',
    render: renderTimestamp,
    sorter: timestampCompare('departSociete'),
    defaultSortOrder: sorter?.[0] === 'departSociete' ? sorter[1] : undefined,
  },
  user?.type === UserType.CLIENT
    ? {
        title: 'Suivre votre colis',
        dataIndex: 'tracking',
        /*...getFilterDropdownProps('tracking', searchState),
        filteredValue: filters?.tracking || undefined,*/
      }
    : {
        title: 'N° de boîte',
        dataIndex: 'noBoite',
        filteredValue: filters?.noBoite || undefined,
        ...getFilterDropdownProps('noBoite', searchState),
      },
  {
    title: '',
    key: 'delete',
    render: function DeleteButton(_, order) {
      return order.etat === ORDER_STATE.EN_ATTENTE ? (
        <Popconfirm
          placement="left"
          title="Êtes-vous sûr de vouloir supprimer cette commande ?"
          onConfirm={() => {
            deleteOrder(order.ID);
          }}
          okText="Oui"
          cancelText="Annuler"
        >
          <Button icon="delete" type="danger" />
        </Popconfirm>
      ) : null;
    },
  },
];

export type PaginationType = [number, number];
export type SorterType = [keyof BetterOrder, SortOrder];
export type FiltersType = Partial<Record<keyof BetterOrder, string[]>>;

export interface OrderListProps {
  orders: BetterOrder[];
  removeOrder: (id: string) => void;
  searchState: StateOf<Search>;
  pagination: PaginationType;
  count: number;
  sorter?: SorterType;
  filters?: FiltersType;
  onTableChange?: (
    _pagination: PaginationConfig,
    _filters: Partial<Record<keyof BetterOrder, string[]>>,
    _sorter: SorterResult<BetterOrder>,
    _extra: TableCurrentDataSource<BetterOrder>,
  ) => void;
  autoOpenModal?: boolean;
}

const OrderList: FC<OrderListProps> = ({
  orders,
  removeOrder,
  searchState,
  pagination,
  count,
  onTableChange,
  sorter,
  filters,
  autoOpenModal = false,
}) => {
  const session = useSession();

  const user = useUser();

  const [quote, setQuote] = useQuote();
  const history = useHistory();

  const [selectedOrder, setSelectedOrder] = useState<BetterOrder>();
  const [client, setClient] = useState<Client>();

  const deleteOrder = async (id: string): Promise<boolean> => {
    const result =
      (await session.fetch<boolean>('Commandes', 'cancelCde', {id})) || false;

    if (result) {
      removeOrder(id);
    }

    return result;
  };

  const showDetailsModal = async (id: string): Promise<void> => {
    const selectedOrder = orders.find((order) => order.ID === id);
    if (selectedOrder) {
      setClient(
        (await session.fetch<Client>('Clients', 'getClient', {
          // eslint-disable-next-line @typescript-eslint/camelcase
          id_client: selectedOrder.id_client.__KEY,
        })) || undefined,
      );
      const baguesIds: string[] = [];
      ['OG', 'OD'].map((oreille): void => {
        if (selectedOrder.details[oreille as 'OG' | 'OD']?.bague) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          baguesIds.push(selectedOrder.details[oreille as 'OG' | 'OD'].bague);
        }

        return undefined;
      });
      const articles = await session.fetch<Article[]>(
        'Articles',
        'getArticlesFromIDs',
        {
          ids: Array.from(new Set(baguesIds)),
        },
      );
      ['OG', 'OD'].map((oreille): void => {
        if (articles && articles.length > 0) {
          if (selectedOrder.details[oreille as 'OG' | 'OD']?.bague) {
            const article = articles.find(
              ({ID}) =>
                ID === selectedOrder.details[oreille as 'OG' | 'OD']?.bague,
            );
            if (article) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              selectedOrder.details[oreille as 'OG' | 'OD'].bague =
                article.libelle;
            }
          }
        }

        return undefined;
      });
      setSelectedOrder(selectedOrder);
    }
  };

  const hideDetailsModal = (): void => {
    setSelectedOrder(undefined);
  };

  const getModalContent = (order: BetterOrder): ReactNode => {
    const surdite = order.details.PATIENT.surdite ? (
      <Descriptions.Item label="Surdité">
        {order.details.PATIENT.surdite}
      </Descriptions.Item>
    ) : null;

    const og = order.details.OG
      ? getOreilleDescription('Oreille gauche', order.details.OG)
      : null;

    const od = order.details.OD
      ? getOreilleDescription('Oreille droite', order.details.OD)
      : null;

    const dateDepart = order.dateSouhaitee ? (
      <Descriptions.Item label="Date départ">
        {`${moment(fixTimestampMilliseconds(order.dateSouhaitee)).format('L')}`}
      </Descriptions.Item>
    ) : null;

    const raisonSociale = client ? (
      <Descriptions.Item label="Client">
        {client.raisonSociale}
      </Descriptions.Item>
    ) : null;

    return (
      <Fragment>
        <Descriptions
          title="Commande"
          bordered
          size="small"
          column={2}
          style={{marginBottom: 16}}
        >
          <Descriptions.Item label="N° commande">
            {`${order.numeroBon}`}
          </Descriptions.Item>
          <Descriptions.Item label="Date de saisie">
            {`${moment(order.dateSaisie).format('L')}`}
          </Descriptions.Item>
          <Descriptions.Item label="Etat">
            <OrderState
              state={
                order.etat === ORDER_STATE.EN_COURS
                  ? order.etatFabrication
                  : order.etat
              }
              info={
                order.etat === ORDER_STATE.EN_COURS
                  ? PRODUCTION_STATE_INFO
                  : ORDER_STATE_INFO
              }
            />
          </Descriptions.Item>
          {dateDepart}
          {raisonSociale}
        </Descriptions>
        <Descriptions
          title="Patient"
          bordered
          size="small"
          column={2}
          style={{marginBottom: 16}}
        >
          <Descriptions.Item label="Nom">{order.patient}</Descriptions.Item>
          {surdite}
        </Descriptions>
        {og}
        {od}
      </Fragment>
    );
  };

  const editOrder = (id: string): void => {
    const selectedOrder = orders.find((order) => order.ID === id);
    if (selectedOrder) {
      const quote = getQuoteFrom4D(selectedOrder);
      if (quote) {
        setQuote(quote);
        history.push('/orders/edit');
      }
    }
  };

  const modalContent = selectedOrder ? getModalContent(selectedOrder) : null;
  if (autoOpenModal && orders.length && !selectedOrder) {
    showDetailsModal(orders[0].ID);
  }

  return (
    <Fragment>
      <Table
        columns={[
          ...columns({
            user,
            searchState,
            deleteOrder,
            showDetailsModal,
            editOrder,
            editOrderWarning: !!quote.lines[0]?.category,
            sorter,
            filters,
          }),
        ]}
        dataSource={orders || []}
        rowKey={(_, index) => index.toString()}
        pagination={{
          position: 'both',
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          current: pagination[0],
          pageSize: pagination[1],
          total: count,
        }}
        locale={{
          emptyText: 'Aucune donnée',
          filterReset: 'Réinit.',
        }}
        onChange={onTableChange}
      />
      <Modal
        width="700px"
        title={
          <div>
            Détails de la commande
            {selectedOrder && user?.type === UserType.CLIENT ? (
              <span
                css={{
                  float: 'right',
                  position: 'relative',
                  top: '-6px',
                  right: '13px',
                }}
              >
                <DocumentModal order={selectedOrder} />
                {selectedOrder.etat === ORDER_STATE.EXPEDIEE ? (
                  <CertificateModal order={selectedOrder} />
                ) : null}
              </span>
            ) : null}
          </div>
        }
        visible={selectedOrder !== undefined}
        // onOk={hideDetailsModal}
        onCancel={hideDetailsModal}
        okButtonProps={{style: {display: 'none'}}}
        cancelText="Fermer"
        closable={!autoOpenModal}
        cancelButtonProps={autoOpenModal ? {style: {display: 'none'}} : {}}
      >
        {modalContent}
      </Modal>
    </Fragment>
  );
};

export default memo(OrderList);
