import {css} from '@emotion/core';
import {Button, Card, Col, Row, Table} from 'antd';
import {ColumnProps} from 'antd/lib/table';
import {FC, Fragment, memo, ReactNode} from 'react';
import {Redirect} from 'react-router-dom';
import {BaseQuoteLine, useQuote} from '../../contexts/Quote';
import {useUser} from '../../contexts/Session';
import ConfirmationButton from '../../util/ConfirmationButton';
import DocumentModal from './DocumentModal';
import OptionDescription from './OptionDescription';
import {ArticleListItem, getOrder} from './util';

const cartLineCss = css`
  border: none;
  margin-bottom: 10px;

  .ant-table-column-title {
    font-weight: bold;
    color: #78787d;
  }

  tbody.ant-table-tbody tr:first-of-type {
    background-color: #ffd2ca;
  }

  .ant-card-head {
    padding-left: 0;
    .ant-card-head-title {
      padding-bottom: 5px;
    }

    .title {
      line-height: 32px;
      font-weight: bold;
      color: #78787d;
      text-transform: uppercase;
    }
  }

  .ant-card-body {
    border-top: solid 1px #78787d;
    background-color: #ececf1;
    color: #78787d;

    .ant-descriptions-item {
      .ant-descriptions-item-label {
        color: #78787d;
      }

      .ant-descriptions-item-content {
        font-weight: bold;
        color: #78787d;
      }
    }
  }
`;

const tableCss = css`
  overflow-x: auto;
  .ant-table-tbody {
    background-color: #ececf1;
  }
`;

interface CartLineProps {
  line: BaseQuoteLine;
  onEdit?: () => void;
  onDelete?: () => void;
}

const CartLine: FC<CartLineProps> = ({line, onEdit, onDelete}) => {
  const {product, options, articles, total} = line;

  const [quote] = useQuote();
  const user = useUser();

  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  const order = getOrder(quote, user, quote.lines.indexOf(line));

  const columns: ColumnProps<ArticleListItem>[] = [
    {
      title: 'N°',
      dataIndex: 'num',
      key: 'num',
    },
    /* {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    }, */
    {
      title: 'Libellé',
      dataIndex: 'libelle',
      key: 'libelle',
    },
    {
      title: 'Oreille',
      dataIndex: 'oreille',
      key: 'oreille',
    },
    {
      align: 'center',
      title: 'Qté',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Unité',
      dataIndex: 'unit',
      key: 'unit',
    },
  ];

  if (user && user.blChiffre) {
    const renderPrice = (price: number): ReactNode => (
      <span>
        {typeof price !== 'number' || !price
          ? '-'
          : price.toFixed(2).replace('.', ',')}
      </span>
    );

    columns.push(
      {
        align: 'right',
        title: 'Px unit. (€)',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        render: renderPrice,
      },
      ...(articles?.find(({discount}) => discount && discount > 0)
        ? ([
            {
              align: 'right',
              title: 'Rem. (%)',
              dataIndex: 'discount',
              key: 'discount',
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              // eslint-disable-next-line react/display-name
              render: (discount) => (
                <span>
                  {discount === 0
                    ? '-'
                    : (discount * 100).toString().replace('.', ',')}
                </span>
              ),
            },
          ] as ColumnProps<ArticleListItem>[])
        : []),
      {
        align: 'right',
        title: 'Px net (€)',
        dataIndex: 'netPrice',
        key: 'netPrice',
        render: renderPrice,
      },
      {
        align: 'right',
        title: 'Px total (€)',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        render: renderPrice,
      },
    );
  }

  const footer: (() => ReactNode) | undefined =
    user && user.blChiffre
      ? () => (
          <Row type="flex" justify="end">
            <Col>
              <span
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                Total: {(total || 0).toFixed(2).replace('.', ',')} €
              </span>
            </Col>
          </Row>
        )
      : undefined;

  return (
    <Card
      size="small"
      css={cartLineCss}
      title={
        <Row type="flex" justify="space-between">
          <Col className="title">{product?.label}</Col>
          <Col>
            <DocumentModal order={order} />

            <Button
              type="default"
              onClick={onEdit}
              title="Modifier produit"
              css={{marginLeft: '2px', marginRight: '2px'}}
              shape="circle"
              style={{border: 'none'}}
            >
              <img src="2022/bouton-modification.svg" alt="Modifier" />
            </Button>

            <ConfirmationButton
              callback={onDelete}
              confirmationText={
                <p>
                  Êtes-vous sûr(e) ?<br />
                  Les options sélectionnées seront perdues.
                </p>
              }
              confirmationButton="Oui, supprimer"
              type="default"
              shape="circle"
              style={css`
                border: none;
              `}
            >
              <img src="2022/bouton-suppression.svg" alt="Supprimer" />
            </ConfirmationButton>
          </Col>
        </Row>
      }
    >
      <OptionDescription
        nocss={true}
        title=""
        options={options}
        showSelectedEars={false}
      />
      {articles && articles.length > 0 ? (
        <Fragment>
          <Table
            css={tableCss}
            columns={columns}
            dataSource={articles}
            pagination={false}
            footer={footer}
          />
        </Fragment>
      ) : null}
    </Card>
  );
};

export default memo(CartLine);
